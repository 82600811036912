<template>
    <main>
        <section class="section section--main section--white">
            <svg class="section__background" xmlns="http://www.w3.org/2000/svg" width="1920" height="882" viewBox="0 0 1920 882" preserveAspectRatio="none">
                <defs>
                    <clipPath id="main-background-mask" clipPathUnits="objectBoundingBox">
                        <path d="M1 0H0V0.950936C0.411323 0.719847 0.730762 1.13632 1 0.950936V0Z" fill="#EAEAEA"/>
                    </clipPath>
                </defs>
                <path fill="#fa5d18" d="M1920 0H0v829.998c789.741-201.699 1397.457 184.25 1920 0V0z"/>
            </svg>
            <div class="section__background section__background--masked">
                <picture>
                    <source srcset="@/assets/images/main-screen-2560.jpg" media="(min-width: 992px)">
                    <source srcset="@/assets/images/main-screen-1000.jpg" media="(min-width: 576px)">
                    <img src="@/assets/images/main-screen-575.jpg" alt="Main background image" />
                </picture>
                <!-- <img src="@/assets/images/original/main-screen-original.jpg" alt="Background image" /> -->
            </div>
            <v-container>
                <v-row class="section__content">
                    <v-col sm="7" class="section__content-text pr-sm-0">
                        <div class="section__title">Земная жизнь – это чудо, которое когда-нибудь заканчивается. Вовремя или не вовремя…, но тепло в сердцах наших близких  и уверенность в продолжении дел – это важно нам всем!</div>
                        <div class="section__desc">
                            <p>При жизни не всегда получается донести близким свои сокровенные чувства, важную информацию или знания. Это может быть морально непросто, неправильно или не вовремя сделать здесь и сейчас.</p>
                            <p>Этот интернет-сервис создан для гарантированной передачи важной информации нашим близким, в случае, если с нами что-то случится.</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </section>

        <section class="section section--procedure section--white">
            <v-container>
                <v-row class="section__content">
                    <v-col cols="12">
                        <div class="d-flex align-center">
                            <div class="round-image round-image--mobile d-md-none">
                                <img src="@/assets/images/happy-people-xs.jpg" alt="Happy people" class="round-image__image" />
                            </div>
                            <h1 class="section__title">
                                Сохранение наших <br /> <span class="primary--text">духовных и цифровых</span> ценностей.
                            </h1>
                        </div>
                        <div class="section__desc">
                            <p>Мы хотим, чтобы с нашим уходом наше наследие не исчезло, чтобы потомки продолжили наше дело, продолжили свою жизнь, как будто мы по-прежнему рядом, любим их и заботимся.</p>
                            <p>Есть многое, что важно оставить нашим близким:</p>
                        </div>
                        <v-row>
                            <v-col md="7" class="section__content-text">
                                <ol class="list list--ordered">
                                    <li class="list__item">
                                        <span>
                                            <b class="font-weight-bold">душевные послания</b> – то, что при жизни морально не просто или не получается передать лично
                                        </span>
                                    </li>
                                    <li class="list__item">
                                        <span>
                                            <b class="font-weight-bold">летопись своей жизни</b> - опыт переживаний, открытий и потерь
                                        </span>
                                    </li>
                                    <li class="list__item">
                                        <span>
                                            <b class="font-weight-bold">советы и рекомендации</b> по эксплуатации дома, ухаживанию за животными, садом…
                                        </span>
                                    </li>
                                    <li class="list__item">
                                        <span><b class="font-weight-bold">знания</b> и важную информацию по бизнесу</span>
                                    </li>
                                    <li class="list__item">
                                        <span>
                                            <b class="font-weight-bold">цифровые ценности</b> в виде кодов и паролей
                                        </span>
                                    </li>
                                </ol>
                            </v-col>
                            <v-col md="5" class="section__content-image d-none d-md-inline-flex justify-end">
                                <div class="round-image">
                                    <img src="@/assets/images/happy-people.jpg" alt="Happy people" class="round-image__image" />
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </section>

        <section class="section section--helpful section--white">
            <svg class="section__background" xmlns="http://www.w3.org/2000/svg" width="0" height="0" viewBox="0 0 1 1" preserveAspectRatio="none">
                <defs>
                    <clipPath id="helpful-background-mask" clipPathUnits="objectBoundingBox">
                        <path fill-rule="evenodd" clip-rule="evenodd" fill="rgb(234, 234, 234)" d="M0.210449 -0.000488281C0.23406 -0.000488281 0.257672 -0.000488281 0.281283 -0.000488281C0.355332 0.00486868 0.429116 0.0175624 0.502637 0.0375928C0.568533 0.0560834 0.634507 0.0735578 0.700553 0.0900162C0.751928 0.102811 0.803491 0.108086 0.855241 0.105842C0.898621 0.103675 0.940809 0.0891674 0.981803 0.0623208C0.987777 0.0581309 0.993678 0.0536799 0.999512 0.0489677C0.999512 0.365815 0.999512 0.682664 0.999512 0.999512C0.666178 0.999512 0.332845 0.999512 -0.000488281 0.999512C-0.000488281 0.682664 -0.000488281 0.365815 -0.000488281 0.0489677C0.0690518 0.0196051 0.139364 0.00311981 0.210449 -0.000488281Z"/>
                    </clipPath>
                </defs>
            </svg>
            <div class="section__background section__background--masked"></div>

            <v-container>
                <v-row class="section__content">
                    <v-col cols="12">
                        <div class="d-flex align-center">
                            <div class="round-image round-image--mobile round-image--offset-right d-md-none">
                                <img src="@/assets/images/grandfather-xs.jpg" alt="The дед" class="round-image__image" />
                            </div>
                            <h2 class="section__title">
                                Электронное «<span class="primary--text">Духовное Послание</span>» -  дополнение к завещанию.
                            </h2>
                        </div>
                        <v-row>
                            <v-col md="5" class="section__content-image d-none d-md-inline-flex">
                                <div class="round-image round-image--offset-right">
                                    <img src="@/assets/images/grandfather.jpg" alt="The дед" class="round-image__image" />
                                </div>
                            </v-col>
                            <v-col md="7" class="section__content-text">
                                <ul class="list list--checkmark">
                                    <li class="list__item"><b class="font-weight-bold">Интернет-сервис бесплатно исполнит</b> нашу волю и вовремя перешлет наши Послания получателям.</li>
                                    <li class="list__item">Живя сегодня счастливой и полноценной жизнью, мы не спеша можем наполнять свое информационное наследие на сайте, которое в случае, если с нами что-то произойдет, <b class="font-weight-bold">гарантированно перейдет нашим близким</b>.</li>
                                    <li class="list__item"><b class="font-weight-bold">Послания - не завещания</b>, они не имеют юридической силы. Это дополнительные, но важные сообщения в виде текста, аудио и видео.</li>
                                </ul>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </section>

        <section class="section section--safety">
            <svg class="section__background" xmlns="http://www.w3.org/2000/svg" width="1920" height="960" viewBox="0 0 1920 960" preserveAspectRatio="none">
                <defs>
                    <clipPath id="safety-background-mask" clipPathUnits="objectBoundingBox">
                        <path d="M1 0.0984363C0.616756 0.22003 0.293551 -0.174577 0 0.0984363C0 0.322688 0 0.829104 0 0.955252C0.411324 0.744497 0.731052 1.12433 1 0.955252C1 0.739958 1 0.3452 1 0.0984363Z"/>
                    </clipPath>
                </defs>
                <path fill-rule="evenodd"  fill="rgb(250, 93, 24)" d="M1919.999,85.999 C1184.171,202.368 563.618,-165.284 0.0,95.999 C0.0,310.614 0.0,805.269 0.0,925.998 C789.741,724.299 1403.62,1077.808 1919.999,915.998 C1919.999,709.954 1919.999,322.159 1919.999,85.999 Z"/>
            </svg>
            <div class="section__background section__background--masked">
                <picture>
                    <source srcset="@/assets/images/safety-screen-2560.jpg" media="(min-width: 1921px)">
                    <source srcset="@/assets/images/safety-screen-1920.jpg" media="(min-width: 992px)">
                    <source srcset="@/assets/images/safety-screen-1000.jpg" media="(min-width: 576px)">
                    <img src="@/assets/images/safety-screen-580.jpg" alt="Safety background image" />
                </picture>
                <!-- <img src="@/assets/images/original/safety-screen-original.jpg" alt="Background image" /> -->
            </div>

            <v-container>
                <div class="section__content">
                    <h2 class="section__title">
                        Мы обеспечиваем<br />
                        максимальную <span class="primary--text">безопасность!</span>
                    </h2>
                    <v-row class="safety-items">
                        <v-col md="4" class="safety-item">
                            <div class="text-center">
                                <img src="@/assets/images/icons/checkmark.svg" class="safety-item__checkmark" alt="" width="33">
                            </div>
                            <div class="safety-item__text">
                                <p>
                                    Вся личная <b class="font-weight-bold">информация абсолютно конфиденциальна</b> и хранится в зашифрованном виде.
                                </p>
                            </div>
                        </v-col>
                        <v-col md="4" class="safety-item">
                            <div class="text-center">
                                <img src="@/assets/images/icons/checkmark.svg" class="safety-item__checkmark" alt="" width="33">
                            </div>
                            <div class="safety-item__text">
                                <p>
                                    <b class="font-weight-bold">Несколько уровней защиты</b> не позволит зайти на личную страницу никому, кроме владельца.
                                </p>
                            </div>
                        </v-col>
                        <v-col md="4" class="safety-item">
                            <div class="text-center">
                                <img src="@/assets/images/icons/checkmark.svg" class="safety-item__checkmark" alt="" width="33">
                            </div>
                            <div class="safety-item__text">
                                <p>
                                    <b class="font-weight-bold">Личность автора Посланий подтверждается</b> выбранными Поверенными, что исключает подделку автора.
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="auth text-center flex-column">
                        <h3 class="section__subtitle"><span class="primary--text font-weight-bold"><a href="/console/sign-in">Зайдите</a></span> в личный кабинет</h3>
                        <v-form class="auth-form" validate-on="submit lazy" @submit.prevent="signIn" v-if="false">
                            <v-text-field
                                v-model="data.email"
                                error-messages="Некорректный e-mail"
                                prepend-icon="mdi-email"
                                name="email"
                                label="Ваш e-mail"
                                mask="email"
                                type="text"
                                class="input input--brown input--round size--x-large"
                                density
                            />
                            <v-text-field
                                v-model="data.password"
                                :error-messages="errors.password"
                                prepend-icon="mdi-lock"
                                name="password"
                                label="Пароль"
                                type="password"
                                class="input input--brown input--round size--x-large"
                                density
                            />
                            <vue-recaptcha
                                style="position: absolute;"
                                ref="recaptcha"
                                size="invisible"
                                :sitekey="siteKey"
                                @verify="signIn"
                                @expired="onCaptchaExpired"
                            />
                            <v-btn
                                :disabled="wait"
                                :class="[{'js-sign-in-wait': wait}]"
                                type="submit"
                                color="primary"
                                elevation="0"
                                rounded
                                :large="$vuetify.breakpoint.smOnly"
                                :x-large="$vuetify.breakpoint.mdAndUp"
                                >Войти</v-btn>
                            <!-- <input type="text" class="input input--brown input--round size--x-large" placeholder="Ваш e-mail" />
                            <input type="password" class="input input--brown input--round size--x-large" placeholder="Пароль" /> -->
                            <!-- <button class="button button--flat-primary button--round size--x-large ripple">Войти</button> -->
                        </v-form>
                        <span class="auth__register">
                            или&nbsp;<br class="d-sm-none" />
                            <a href="/console/sign-up" class="link link--underscore link--primary"><span>зарегистрируйтесь</span></a>
                        </span>
                    </v-row>
                    <v-row class="messages-check text-center flex-column">
                        <h3 class="section__subtitle"><span class="primary--text font-weight-bold">Код доступа</span> для прочтения Послания</h3>
                        <router-link tag="button" to="/message" class="button button--flat-primary button--round size--x-large align-self-center ripple">Ввести</router-link>
                    </v-row>
                </div>
            </v-container>
        </section>

        <section class="section section--articles section--white">
            <v-container>
                <h2 class="section__title">Статьи</h2>
                <v-row class="articles flex-wrap">
                    <v-col
                        v-for="(a, i) in articles"
                        :key="i"
                        cols="12"
                        md="4"
                        lg="3"
                        class="article"
                    >
                        <router-link :to="a.url" class="link d-flex flex-md-column">
                            <div class="article__image">
                                <img :src="'/api/landing/article/image/' + a.image" alt="Article image" />
                            </div>
                            <h6 class="article__title" :title="a.title">{{ a.title }}</h6>
                        </router-link>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="text-center">
                        <router-link :to="{name: 'articles', hash: '#articles'}" class="button button--outline button--round size--x-large ripple align-self-center" draggable="false">Все статьи</router-link>
                    </v-col>
                </v-row>
            </v-container>
        </section>
    </main>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha'

export default {
    name: "DefaultController",
    components: { VueRecaptcha },
    metaInfo () {
        return {
            title: this.meta.title,
            meta: [
                {name: 'description', content: this.meta.description},
                {name: 'keywords', content: this.meta.keywords},
            ],
        }
    },
    data () {
        return {
            articles: null,
            loading: true,
            wait: false,
            data: {
                email: '',
                password: '',
                stage: 'init',
            },
            errors: {
                captcha: [],
                email: [],
                password: [],
            },
            meta: {
                description: '',
                keywords: '',
                title: '',
            },
            siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
        }
    },
    created () {
        this.searchMeta()
        this.searchArticles()
    },
    methods: {
        searchArticles () {
            this.loading = true

            this.$axios.post('/api/landing/article', {url: this.$router.currentRoute.path})
                .then(res => {
                    if (res.data.success) {
                        this.articles = res.data.data.slice(0, 8)
                    }

                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },

        searchMeta () {
            this.$axios.post('/api/landing/article/meta', {url: this.$router.currentRoute.path})
                .then(res => {
                    if (res.data.success) {
                        this.meta = res.data.data
                    }
                })
                .catch(() => {})
        },

        signIn (recaptchaToken) {
            this.wait = true

            this.$axios.post('/api/console/auth', {...this.data, captcha: recaptchaToken})
                .then(res => {
                    if (res.data.success) {
                        if (res.data.data.stage === 'success') {
                            this.$axios.defaults.headers.common['Authorization'] = res.data.data.token
                            this.$store.commit('updateToken', res.data.data.token)
                            this.$router.push('/')
                        } else {
                            this.twofactor.call = res.data.data.call
                            this.twofactor.email = res.data.data.email
                            this.twofactor.sms = res.data.data.sms
                            this.twofactor.telegram = res.data.data.telegram
                            this.data.stage = res.data.data.stage
                            this.data.twofactor_method = res.data.data.twofactor_method
                            this.$refs.recaptcha.reset()
                        }
                    } else {
                        this.errors.email = res.data.errors.email || []
                        this.errors.password = res.data.errors.password || []
                        this.errors.twofactor_code = res.data.errors.twofactor_code || []

                        if (this.errors.captcha.length) {
                            this.$refs.recaptcha.reset()
                        }
                    }

                    this.wait = false
                })
                .catch(() => {
                    this.wait = false
                })
        },

        validate () {
            this.$refs.recaptcha.execute()
        },
    },
}
</script>

<style scoped lang="scss">
    .section {
        &--helpful {
            .section__background--masked {
                background-image: url('~@/assets/images/riffle.png');
            }
        }
    }
</style>
