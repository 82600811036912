<template>
    <transition name="fade" mode="out-in">
        <v-container v-if="article" class="pb-16 mb-8">
            <nav class="breadcrumbs" role="navigation" aria-labelledby="breadcrumbs_heading">
                <ol class="breadcrumbs__list">
                    <li class="breadcrumbs__item">
                        <router-link to="/" class="breadcrumbs__link link link--underscore">
                            <svg width="18px" height="16px" class="breadcrumbs__house" role="img">
                                <use xlink:href="@/assets/images/icons/icons.svg#icon-house"></use>
                            </svg>
                            <span>Главная</span>
                        </router-link>
                        <svg width="13px" height="13px" class="breadcrumbs__chevron" role="img">
                            <use xlink:href="@/assets/images/icons/icons.svg#icon-chevron_right"></use>
                        </svg>
                    </li>
                    <li class="breadcrumbs__item">
                        <router-link to="/articles" class="breadcrumbs__link link link--underscore">
                            <span>Статьи</span>
                        </router-link>
                        <svg width="13px" height="13px" class="breadcrumbs__chevron" role="img">
                            <use xlink:href="@/assets/images/icons/icons.svg#icon-chevron_right"></use>
                        </svg>
                    </li>
                    <li class="breadcrumbs__item">
                        <span class="breadcrumbs__link" aria-current="location">{{ article.title }}</span>
                    </li>
                </ol>
            </nav>

            <v-card v-if="article" color="transparent" flat>
                <h1 class="section__title mb-lg-14">{{ article.title }}</h1>
                <v-card-text
                    class="article-item"
                >
                    <div v-html="article.contents"></div>
                </v-card-text>
            </v-card>
            <v-card v-else-if="!loading">
                <v-toolbar
                    flat
                    dense
                    class="pa-auto grey lighten-4"
                >
                    <v-toolbar-title>Страница не найдена</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="py-16 text-center">
                    Страница не найдена
                </v-card-text>
            </v-card>
        </v-container>
    </transition>
</template>

<script>
export default {
    name: 'ArticleController',
    metaInfo () {
        return {
            title: this.meta.title,
            meta: [
                {name: 'description', content: this.meta.description},
                {name: 'keywords', content: this.meta.keywords},
            ],
        }
    },
    data () {
        return {
            article: null,
            loading: true,
            meta: {
                description: '',
                keywords: '',
                title: '',
            },
        }
    },
    created () {
        this.searchArticle()
        this.searchMeta()
    },
    methods: {
        searchArticle () {
            this.loading = true

            this.$axios.post('/api/landing/article/search', {url: this.$router.currentRoute.path})
                .then(res => {
                    if (res.data.success) {
                        if (res.data.data.url === this.$router.currentRoute.path) {
                            this.article = res.data.data
                        } else {
                            this.$router.push(res.data.data.url)
                        }
                    }

                    this.loading = false
                })
                .catch(() => {
                    this.loading = false

                    this.$router.push('/404')
                })
        },

        searchMeta () {
            this.$axios.post('/api/landing/article/meta', {url: this.$router.currentRoute.path})
                .then(res => {
                    if (res.data.success) {
                        this.meta = res.data.data
                    }
                })
                .catch(() => {})
        },
    },
}
</script>

<style lang="scss" scoped>
.article-item {
    padding: 0;
    font-size: 1rem;
    line-height: 1.5;

    img {
        max-width: 100%;
    }
}
.theme--light.v-card > .article-item {
    color: map-deep-get($theme-light, 'text', 'primary');
}
</style>
