<template>
    <footer class="footer" :class="isBackgroundWhite ? 'section--white' : ''">
        <v-container>
            <div class="d-flex flex-column flex-md-row">
                <div class="footer__info">
                    <div class="footer__info-logo">
                        <span class="footer__info-logo-title footer__info-logo-title--ru">Духовное послание</span>
                        <span class="footer__info-logo-caption">Твоя воля и чувства вечны</span>
                    </div>
                    <p class="footer__info-copyright">© Духовное послание | 2021</p>
                </div>
                <div class="footer__actions">
                    <div class="footer__actions-socials d-flex">
                        <a href="https://vk.com/" target="_blank" class="footer__actions-socials-item vk">
                            <button type="button" class="button button--flat-grey button--round ripple">
                                <svg class="icon" width="16px" height="16px" role="img">
                                    <use xlink:href="@/assets/images/icons/icons.svg#icon-vk"></use>
                                </svg>
                            </button>
                        </a>
                        <a href="https://fb.com/" target="_blank" class="footer__actions-socials-item facebook">
                            <button type="button" class="button button--flat-grey button--round ripple">
                                <svg class="icon" width="16px" height="16px" role="img">
                                    <use xlink:href="@/assets/images/icons/icons.svg#icon-facebook"></use>
                                </svg>
                            </button>
                        </a>
                        <a href="https://ok.ru/" target="_blank" class="footer__actions-socials-item odnoklassniki">
                            <button type="button" class="button button--flat-grey button--round ripple">
                                <svg class="icon" width="16px" height="16px" role="img">
                                    <use xlink:href="@/assets/images/icons/icons.svg#icon-odnoklassniki"></use>
                                </svg>
                            </button>
                        </a>
                        <a href="https://twitter.com/" target="_blank" class="footer__actions-socials-item twitter">
                            <button type="button" class="button button--flat-grey button--round ripple">
                                <svg class="icon" width="16px" height="16px" role="img">
                                    <use xlink:href="@/assets/images/icons/icons.svg#icon-twitter"></use>
                                </svg>
                            </button>
                        </a>
                    </div>
                    <div class="footer__actions-links">
                        <router-link to="/feedback" class="footer__actions-links-item link link--underscore"><span>Задать вопрос</span></router-link>
                        <router-link to="/privacy" class="footer__actions-links-item link link--underscore"><span>Политика обработки персональных данных</span></router-link>
                        <router-link to="/agreement" class="footer__actions-links-item link link--underscore"><span>Публичная оферта</span></router-link>
                    </div>
                </div>
                <div class="footer__info">
                    <div class="footer__info-logo">
                        <span class="footer__info-logo-title">Spiritual Will</span>
                        <span class="footer__info-logo-caption">Your will and feelings are eternal</span>
                    </div>
                    <p class="footer__info-copyright">© Spiritual Will | 2021</p>
                </div>
            </div>
        </v-container>
    </footer>
</template>

<script>
export default {
    props: {
        isBackgroundWhite: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            // something
        }
    },
}
</script>
