import Vue from 'vue'
import Vue2Filters from 'vue2-filters'
import VueMoment from 'vue-moment'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './plugins/axios'
import i18n from './i18n'

Vue.use(Vue2Filters)
Vue.use(VueMoment)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
    i18n,

    created: function () {
        this.$axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
        this.$i18n.locale = this.$store.getters.language || 'en_US'

        const authToken = localStorage.getItem('auth-token')

        if (authToken) {
            this.$axios.defaults.headers.common['Authorization'] = authToken
        }

        const self = this

        document.querySelector('html').setAttribute('lang', this.$i18n.locale);

        this.$axios.defaults.withCredentials = true

        this.$axios.interceptors.response.use(
            function (res) {
                if (res.headers['x-country'] && self.$store.getters.user) {
                    self.$store.commit('updateUserCountry', res.headers['x-country'])
                }

                if (res.headers['x-language'] && self.$store.getters.language) {
                    self.$store.commit('updateLanguage', res.headers['x-language'])
                }

                return Promise.resolve(res)
            },
            function (error) {
                if (error.response.status === 401 || error.response.status === 403) {
                    self.$store.commit('removeToken')
                    self.$store.commit('removeUser')
                    self.$router.push('/')
                    return Promise.reject(error)
                }
            }
        )
    }
}).$mount('#app')
